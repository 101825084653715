<template>
  <div class="page">
    <van-nav-bar
      fixed
      title="详情"
      left-arrow
      @click-left="navBack"
    ></van-nav-bar>
    <div class="container">
      <van-cell title="巡检方式" :value="item.modeName" />
      <van-cell title="巡检项目" :value="item.project" />
      <van-cell title="检验间隔(天)" :value="item.checkInterval" />
      <van-cell title="允许延期(天)" :value="item.delay" />
      <van-cell title="最近执行日期" :value="item.latestDate" />
      <van-cell title="检查方式" :value="item.checkMethodName" />
      <template v-if="item.checkMethod === '20' || item.checkMethod === '30'">
        <van-cell title="上限值" :value="item.upBounds" />
        <van-cell title="下限值" :value="item.downBounds" />
      </template>
      <van-cell v-else title="检测标准" :value="item.checkStandard" />
      <van-cell title="单位" :value="item.units" />
      <van-cell title="工具需求" :value="item.toolNeed" />
    </div>
  </div>
</template>

<script>
export default {
  name: "EquipmentInspectionPlanDetail",
  data() {
    return {
      item: {}
    };
  },
  created() {
    this.$nextTick(() => {
      this.item = this.$route.params.item;
    });
  },
  methods: {
    navBack() {
      this.$router.back();
    }
  }
};
</script>

<style lang="scss" scoped>
.page {
  height: 750px;
  overflow: hidden;

  .container {
    height: 750px;
    margin-top: 58px;
  }
}
</style>
